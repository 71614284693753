import React from 'react'
import Highlighter from "react-highlight-words"
import { find, } from 'lodash'
import { Card } from 'semantic-ui-react'

const getSeconds = (timeStamp) => {
  const splits = timeStamp.split(':')
  const hours = parseInt(splits[0]) || 0
  const minutes = parseInt(splits[1]) || 0
  const seconds = parseInt(splits[2]) || 0

  return (hours * 60 * 60) + (minutes * 60) + (seconds)
}

export const Video = (props) => {

  const {
    video,
    highlightWord,
  } = props

  return (
    <Card fluid className='video-card'>
      <Card.Content>
        <Card.Header><h5>
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[highlightWord]}
            textToHighlight={video.title}
            caseSensitive={false}
            autoEscape={true}
            sanitize={string => string.replace(`'`, '')}
          />
        </h5></Card.Header>
      </Card.Content>
      <Card.Content>
        <div className='grid-x align-middle align-center'>
          <div className='cell shrink'>
            <a href={`https://youtube.com/watch?v=${video.id}`} target='_blank'>
              <img src={find(video.thumbnails, { type: 'medium' })?.url} alt='Thumbnail' />
            </a>
          </div>
          <div className='cell small-12 medium-6'>
            <ul>
              {
                video.chapters.map((chapter, i) => {
                  return (
                    <a key={i} target='_blank' href={`https://youtube.com/watch?v=${video.id}&t=${getSeconds(chapter.timeStamp)}`} >
                      <li>
                        <div className='grid-x' style={chapter['title'].toLowerCase().includes(highlightWord.toLowerCase()) ? { fontWeight: 'bold' } : {}}>
                          <div className='cell shrink'>
                          {chapter.timeStamp}
                          </div>
                          <div className='cell auto ml-2' style={{color: 'black'}}>
                            <Highlighter
                              highlightClassName="YourHighlightClass"
                              searchWords={[highlightWord]}
                              textToHighlight={chapter.title}
                              caseSensitive={false}
                              autoEscape={true}
                              sanitize={string => string.replace(`'`, '')}
                            />
                          </div>
                        </div>
                      </li>
                    </a>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </Card.Content>
    </Card>
  )
}

export default Video