import React from 'react'
import { useSelector } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'

import Video from 'video'

import './index.css'

export const SearchResults = (props) => {

  const searchResults = useSelector(state => state.searchBar.results.data)
  const searchValue = useSelector(state => state.searchBar.searchValue)
  const isLoading = useSelector((state) => state.searchBar.isLoading)

  return (
    // <div id='scrollable-container' style={{ height: `calc(100vh - 100px)`, overflowY: 'auto', overflowX: 'hidden' }}>
    <div className='grid-x align-center'>
      {
        isLoading &&
        <div className='cell shrink my-4'>
            <Loader
              centered
              inline
              active
              size='large'
            >
              <h5>{`${searchValue.length < 2 ? 'Keep typing ...' : 'Searching for Tunes ...'}`}</h5>
            </Loader>
        </div>
      }
      {
        !isLoading && searchValue.length > 2 && searchResults.length === 0 &&
          <div className='grid-x align-center text-center'>
            <div className='cell small-12'>
              <img alt='Acacado' title='Avacado is lost' src='/avacado.svg' width='200px' />
            </div>
            <div className='cell small-12 mb-2'>
              <h2>No Results Found</h2>
            </div>
            <div className='cell small-12'>
              <h4>We couldn't find any results for <i>{searchValue}</i>.</h4>
            </div>
          </div>
      }
      {
        !isLoading && searchResults.length > 0 &&
      <div className='grid-x'>
        <div className='cell small-12'>
          Found <b>{searchResults.length}</b> matches for <b>"{searchValue}"</b>
        </div>
        {
          searchResults.map((video, i) => {
            return (
              <div key={i} className='cell my-3'>
                <Video video={video} highlightWord={searchValue}/>
              </div>
            )
          })
        }
      </div>
    }
    </div>
  )
}

export default SearchResults