import { axios } from 'libs'

export const fetchAll = async (params={}) => {
  let url = `${process.env.REACT_APP_API_URL}`
  
  const response = await axios({
    url,
    method: 'GET',
    params: {
      ...params,
      type: 'artists'
    },
  })

  return response
}


export const fetch = async (params) => {
  let url = `${process.env.REACT_APP_API_URL}`
  
  const response = await axios({
    url,
    method: 'GET',
    params: {
      ...params,
      type: 'artists'
    },
  })

  return response
}
