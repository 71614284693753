import React from 'react'
import { Routes, Route } from 'react-router-dom'

import App from './app'
import Tune from './tune'
import Tunes from './tunes'

import NotLoggedIn from 'layout/not-logged-in'


export const routes = [
  {
    path: "/tunes/:slug",
    element: <Tune />,
  },
  {
    path: "/tunes",
    element: <Tunes />,
  },
  {
    path: "/",
    element: <App />,
  },
]

export default () => {
  return (
    <Routes>
      <Route element={<NotLoggedIn />}>
        {
          routes.map((route, i) => {
            return (
              <Route key={i} {...route} />
            )
          })
        }
      </Route>
    </Routes>
  )
}