import React from 'react'
import { Link } from 'react-router-dom'

class NotLoggedInHeader extends React.PureComponent {
  render() {
    return (
      <div className='grid-x align-justify'>
        <div className="cell shrink mb-3">
          <Link
            to='/'>
            <img src='/tune-supply-logo-black.png' style={{ height: '60px' }} alt='Tune Supply Logo' />
          </Link>
        </div>
      </div>
    )
  }
}

export default NotLoggedInHeader