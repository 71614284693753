import { createStore, applyMiddleware, compose, combineReducers, } from 'redux'
import { createMiddleware, createLoader, reducer as reduxStorageReducer} from 'redux-storage'
import createEngine from 'redux-storage-engine-localstorage'
import filter from 'redux-storage-decorator-filter'
import thunk from 'redux-thunk'

import { reducer as app } from 'app/reducer'
import { reducer as searchBar } from 'search-bar/reducer'
import { reducer as tunes } from 'tunes/reducer'
import { reducer as tune } from 'tune/reducer'
import { reducer as artists } from 'artists/reducer'


/**
 * setup redux localstorage
 */
const whiteList = [
]

const engine = filter(createEngine(process.env.REACT_APP_REDUX_LOCALSTORAGE_KEY), whiteList)
const localStorageMiddleware = createMiddleware(engine)

/**
 * setup redux dev tool
 */
let composeEnhancers = (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// switch devtool off during production
if (process.env.NODE_ENV === `production`) {
  composeEnhancers = compose
}

const load = createLoader(engine)

const store = createStore(
  reduxStorageReducer(
    combineReducers({
      app,
      searchBar,
      tunes,
      tune,
      artists,
    })),
  composeEnhancers(
    applyMiddleware(thunk, localStorageMiddleware),
  )
)

load(store)
  .then(newState => console.log(`Loaded state`))
  .catch((e) => console.log(`failed to load previous state`, e, e.message))

export default store