import React, { useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchAll } from './actions'

import './index.css'

export const Tunes = (props) => {

    const tunes = useSelector(state => state.tunes.results.data)
    const dispatch = useDispatch()

    useEffect(() => {
        if (tunes.length === 0) {
            dispatch(fetchAll())
        }
    }, [])

    let firstLetter = ''
    return (
        <div className='grid-container'>
            <div
                className="grid-y grid-frame tune-supply-search h-100"
            >
                <div
                    className="cell shrink main-header"
                >
                    {/* <h3 className='text-center title'>Browse By Tune</h3> */}
                </div>
                <div className='cell my-4' id='tunes-list'>
                    {
                        tunes.map((tune, i) => {
                            const newFirstLetter = tune.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").charAt(0)

                            if (firstLetter.toLowerCase() !== newFirstLetter.toLowerCase()) {
                                firstLetter = newFirstLetter
                                return (
                                    <div key={i}>
                                        <div key={`not-${i}`} className=' my-2'>
                                            <h4>{firstLetter}</h4>
                                        </div>
                                        <div className='' key={i}>
                                            <Link
                                                style={{ textDecoration: 'none' }}
                                                to={`/tunes/${tune.slug}`}
                                            >
                                                {tune.name}
                                            </Link>
                                        </div>
                                    </div>

                                )
                            }
                            else {
                                return (
                                    <div key={i}>
                                        <Link
                                            style={{ textDecoration: 'none' }}
                                            to={`/tunes/${tune.slug}`}
                                        >
                                            {tune.name}
                                        </Link>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>

    )
}

export default Tunes