import React, { useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchAll } from './actions'

import './index.css'

export const Artists = (props) => {

    const artists = useSelector(state => state.artists.results.data)
    const dispatch = useDispatch()

    useEffect(() => {
        if (artists.length === 0) {
            dispatch(fetchAll())
        }
    }, [])

    let firstLetter = ''
    return (
        <div className='grid-container'>
            <div
                className="grid-y grid-frame tune-supply-search h-100"
            >
                <div
                    className="cell shrink main-header"
                >
                    {/* <h3 className='text-center title'>Browse By Artist</h3> */}
                </div>
                <div className='cell my-4' id='artists-list'>
                    {
                        artists.map((artist, i) => {
                            const newFirstLetter = artist.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").charAt(0)

                            if (firstLetter.toLowerCase() !== newFirstLetter.toLowerCase()) {
                                firstLetter = newFirstLetter
                                return (
                                    <>
                                        <div key={`not-${i}`} className=' my-2'>
                                            <h4>{firstLetter}</h4>
                                        </div>
                                        <div className='' key={i}>
                                            {artist.name}
                                        </div>
                                    </>

                                )
                            }
                            else {
                                return (
                                    <div key={i}>
                                        {artist.name}
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>

    )
}

export default Artists