import * as React from 'react'
import { connect } from 'react-redux'

export const Footer = (props) => {

  const {
    version,
  } = props

  return (
    <div className='grid-x align-justify footer'>
      <div className='cell shrink mb-1'>
        Made with ♥ in Brooklyn, NYC
      </div>
      <div className='cell shrink'>
        <div className='grid-x'>
          <div className='cell shrink'>
            <span className='mx-1'> | </span>
          </div>
          <div className='cell shrink'>
            <span className='mx-1'> | </span>
          </div>
          <div className='cell shrink'>
            <span className='mx-1'> | </span>
          </div>
          <div className='cell shrink'>
          </div>
        </div>
      </div>
      <div className='w-100'></div>
      <div className='cell shrink'>
      </div>
      <div className='cell shrink'>
        App Version: {version}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    version: state.app?.version
  }
}

export default connect(mapStateToProps)(Footer)
