import React, { useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, } from 'react-router-dom'
import { find, } from 'lodash'
import { Icon, Loader } from 'semantic-ui-react'
import { fetchAll as fetchAllTunes } from 'tunes/actions'
import { fetchAll, reset } from './actions'

import Video from 'video'


export const Tune = (props) => {

  let params = useParams()
  const dispatch = useDispatch()

  const videos = useSelector((state) => state.tune.videos.results.data)
  const isLoading = useSelector((state) => state.tune.isLoading)
  const tunes = useSelector(state => state.tunes.results.data)
  const tune = find(tunes, { slug: params.slug }) || {}
  console.log({ tune })

  useEffect(() => {
    if (tunes.length === 0) {
      dispatch(fetchAllTunes())
    }
    dispatch(fetchAll({
      tuneId: params.slug
    }))
    return () => dispatch(reset())
  }, [])

  return (
    <div className='grid-container'>
      <div className='grid-x'>
        <div className='cell small-12 mb-3'>
          <h1 className='title text-center'>{tune.name}</h1>
        </div>
        <div className='cell small-12'>
          <div className='grid-x'>
            {
              isLoading ?
                <div className='cell shrink my-4'>
                  <Loader
                    centered
                    inline
                    active
                    size='large'
                  >
                    <h5>Loading ...</h5>
                  </Loader>
                </div>
                :
                <div className='grid-x'>
                  <div className='cell small-12 mb-3'>
                  <Link
                    to='/'>
                      <h5>
                      <Icon name='left arrow' />
                      Go Back To Search 
                      </h5>
                    </Link>
                  </div>
                  <div className='cell small-12'>
                    <b>{tune.name}</b> has been played <b>{videos.length}</b> times
                  </div>
                  {
                    videos.map((video, i) => {
                      return (
                        <div key={i} className='cell my-3'>
                          <Video video={video} highlightWord={tune.name} />
                        </div>
                      )
                    })
                  }
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tune