import * as actionTypes from './action-types'

const defaultState = {
  results: {
    data: [],
  },
  error: undefined,
  isLoading: false,
  searchValue: '',
  lastSearchValue: '',
}
/**
 * reducer for the state
 */
export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_FULFILLED:
      return {
        ...state,
        ...action.payload.results,
        isLoading: false,
        error: false,
        lastSearchValue: action.payload.lastSearchValue,
      }
    case actionTypes.FETCH_ALL_PENDING:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.FETCH_ALL_FAILED:
      return {
        ...state,
        error: Math.random(), // need to refresh state slice
        isLoading: false,
      }
    case actionTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      }
    case actionTypes.CLEAR_ALL_RESULTS:
      return {
        ...state,
        results: {
          data: [],
        },
      }
    case 'REDUX_STORAGE_LOAD':
      return {
        ...state,
        reduxStorageLoaded: true,
      };
    default:
      return state
  }
}
