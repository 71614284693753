import * as actionTypes from './action-types'
import { Videos } from "api"

const fetchAllFailed = (payload) => {
    return {
      type: actionTypes.FETCH_ALL_FAILED,
      payload,
    }
  }
  
  export const fetchAllPending = (payload) => {
    return {
      type: actionTypes.FETCH_ALL_PENDING,
      payload,
    }
  }
  
  const fetchAllFulfilled = (payload) => {
    return {
      type: actionTypes.FETCH_ALL_FULFILLED,
      payload,
    }
  }

export const fetchAll =  (params) => {
    return async (dispatch, getState) => {
        const response = await Videos.fetchAll(params)
        console.log({response})
        dispatch(fetchAllFulfilled(response.data))
    }
}
export const reset =  (params) => {
    return {
        type: actionTypes.RESET
    }
}