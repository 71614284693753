import React from 'react'
import { Outlet } from 'react-router-dom'

import NotLoggedInHeader from './not-logged-in-header'
import Footer from './footer'

import './index.css'

export const NotLoggedIn = (props) => {

  console.log(props)

  return (
    <div className='grid-x m-3' >
      <div className='cell'>
        <NotLoggedInHeader />
      </div>
      <div className='cell'>
            <Outlet />
      </div>
      <div className='cell'>
        <Footer />
      </div>
    </div>
  )
}

export default NotLoggedIn