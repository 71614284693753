import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'semantic-ui-react'
import { debounce } from 'lodash'

import { setSearchValue, fetchAll, fetchAllPending, clearResults } from './actions'

import './index.css'

export const SearchBar = () => {

  const lastSarchValue = useSelector((state) => state.searchBar.lastSearchValue)
  const searchValue = useSelector((state) => state.searchBar.searchValue)
  const dispatch = useDispatch()

  const handleSearchChange = (event, data) => {
    dispatch(setSearchValue(data.value))
  }

  const getResults = useCallback(
    debounce((searchValue) => {
      dispatch(fetchAll({ searchValue }))
    }, 500),
    []
  )

  useEffect(() => {
    if (searchValue === lastSarchValue) {
      return 
    }
    if (searchValue !== "") {
      dispatch(fetchAllPending())
    }
    if (searchValue !== "" && searchValue.length > 2) {
      dispatch(fetchAllPending())
      getResults(searchValue);
    }
    else {
      dispatch(clearResults())
    }
  }, [searchValue]);



  return (
    <Input
      fluid
      autoFocus
      icon='search'
      size='massive'
      placeholder='Search for a tune or artist ...'
      category
      onChange={handleSearchChange}
      value={searchValue}
    />
  )
}


export default SearchBar