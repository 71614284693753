import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Divider, Tab } from 'semantic-ui-react'

import Artists from 'artists'
import Tunes from 'tunes'
import SearchBar from 'search-bar'
import SearchResults from 'search-results'

import { fetchAll as fetchAllTunes } from 'tunes/actions'
import { fetchAll as fetchAllArtists } from 'artists/actions'

import './index.css'

const App = () => {
  const dispatch = useDispatch()
  const tunes = useSelector((state) => state.tunes.results.data)
  const artists = useSelector((state) => state.artists.results.data)
  const searchValue = useSelector(state => state.searchBar.searchValue)

  const [activeIndex, setActiveIndex] = useState(0)

  const panes = [
    {
      menuItem: 'Browse by tune',
      render: () => <Tab.Pane attached={false}><Tunes /></Tab.Pane>,
    },
    {
      menuItem: 'Browse by artist',
      render: () => <Tab.Pane attached={false}><Artists /></Tab.Pane>,
    },
    {
      menuItem: searchValue.length > 0 ? 'Search Results' : '',
      render: () => <Tab.Pane attached={false}><SearchResults /></Tab.Pane>,
    },
  ]

  useEffect(() => {
    if (tunes.length === 0) {
      dispatch(fetchAllTunes())
    }
    if (artists.length === 0) {
      dispatch(fetchAllArtists())
    }
  }, [])

  useEffect(() => {
    if (searchValue.length > 0) {
      setActiveIndex(2)
    }
    if (searchValue.length === 0) {
      setActiveIndex(0)
    }
  }, [searchValue])

  return (
    <div className='grid-container'>
      <div
        className="grid-y grid-frame tune-supply-search h-100"
      >
        <div
          className="cell shrink main-header"
        >
          <Divider />
          <h1 className='text-center title'>Supply Search</h1>
        </div>
        <div className='cell shrink mt-4 '>
          <SearchBar />
        </div>
        <div className='cell shrink'>
          <Tab
            activeIndex={activeIndex}
            onTabChange={(e, {activeIndex}) => setActiveIndex(activeIndex)}
            panes={panes}
            menu={{ text: true }} 
          />
        </div>
      </div>
    </div>
  )
}

export default App