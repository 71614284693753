import * as actionTypes from './action-types'

const defaultState = {
  results: {
      data: [],
  },
  error: undefined,
  isLoading: false,
}
/**
 * reducer for the state
 */
export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_FULFILLED:
        console.log(action.payload)
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: false,
      }
    case actionTypes.FETCH_ALL_FAILED:
      return {
        ...state,
        error: Math.random(), // need to refresh state slice
        isPageLoading: false,
      }
    default:
      return state
  }
}
