import * as actionTypes from './action-types'
import { get, orderBy } from 'lodash'
import { Videos } from 'api'

const fetchAllFailed = (payload) => {
  return {
    type: actionTypes.FETCH_ALL_FAILED,
    payload,
  }
}

export const fetchAllPending = (payload) => {
  return {
    type: actionTypes.FETCH_ALL_PENDING,
    payload,
  }
}

const fetchAllFulfilled = (payload) => {
  return {
    type: actionTypes.FETCH_ALL_FULFILLED,
    payload,
  }
}

export const fetchAll = ({searchValue}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchAllPending())
      let response = await Videos.fetchAll({ term: searchValue, })

      dispatch(fetchAllFulfilled({
        results: response?.data,
        lastSearchValue: searchValue,
      }))
    }
    catch (e) {
      console.error(e)
      dispatch(fetchAllFailed())
    }
  }
}

export const clearResults = () => {
  return {
    type: actionTypes.CLEAR_ALL_RESULTS,
  }
}

export const setSearchValue = (payload) => {
  return {
    type: actionTypes.SET_SEARCH_VALUE,
    payload,
  }
}