import * as actionTypes from './action-types'
import { Tunes } from 'api'


export const fetchAll = () => {
    return async (dispatch, getState) => {
        try {
            
            const response = await Tunes.fetchAll()
            const tunes = response.data

            dispatch(fetchAllFulfilled(tunes))
        }
        catch (e) {
            console.error(e)
        }
    } 
}

const fetchAllFulfilled = (payload) => {
    return {
        type: actionTypes.FETCH_ALL_FULFILLED,
        payload,
    }
}