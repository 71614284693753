import * as actionTypes from './action-types'

const defaultState = {
    videos: {
        results: {
            data: []
        }
    },
    error: undefined,
    isLoading: false,
}
/**
 * reducer for the state
 */
export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.RESET:
            return defaultState
        case actionTypes.FETCH_ALL_FULFILLED:
            return {
              ...state,
              videos: {
                  ...action.payload
              },
              isLoading: false,
              error: false,
            }
          case actionTypes.FETCH_ALL_PENDING:
            return {
              ...state,
              isLoading: true,
            }
          case actionTypes.FETCH_ALL_FAILED:
            return {
              ...state,
              error: Math.random(), // need to refresh state slice
              isLoading: false,
            }
        default:
            return state
    }
}
