import axios from 'axios'
import humps from 'humps'
import { get } from 'lodash'

import { store } from 'index'

// Create an instance using the config defaults provided by the library
// At this point the timeout config value is `0` as is the default for the library
const instance = axios.create({
  headers: {
    'Content-Type': `application/json`,
  },
})

// Add a request interceptor
instance.interceptors.request.use((config) => {
  // Send all API requests with snake_cased keys
  let transformedParams = {}
  let transformedData = {}

  if (config.params) {
    transformedParams = humps.decamelizeKeys(config.params, { separator: '_' })
  }
  if (config.data) {
    transformedData = humps.decamelizeKeys(config.data, { separator: '_' })
  }

  return {
    ...config,
    params: transformedParams,
    data: transformedData,
  }
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

instance.interceptors.response.use(response => {

  const modifiedResponse = {
    ...response,
    data: humps.camelizeKeys(response.data)
  }
  return modifiedResponse
}, error => {
  console.log(`axios interceptor error`, get(error, `response.status`, `status not found`), error)
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    // The user's token is no longer valid, log them out
    if (error.response.status === 403) {
      console.error(error.response.status)
      // We could log the user out here if they are unauthorized
      console.error(error)
    }

    return Promise.reject(humps.camelizeKeys(error.response));

  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }

  return Promise.reject(error);
})

export default instance
