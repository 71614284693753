import packageJson from '../../package.json'
import { get } from 'lodash'

const defaultState = {
  version: get(packageJson, 'version', ''),
};

export const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    default:
      return state
  }
}